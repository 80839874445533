/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Toolbar,
  AppBar,
  withStyles,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import TimerIcon from '@material-ui/icons/Timer';
import NavItem from './NavItem';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import Person2Icon from '@mui/icons-material/Person2';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#FFFFFF'
  }
})(AppBar);

const sections = [
  {
    items: [
      {
        title: 'Home',
        href: '/app/admin/home',
        icon: 'material-symbols:home-outline'
      },
      // {
      //   title: 'Profile',
      //   href: '/app/admin/profile',
      //   icon: AccountCircleOutlinedIcon
      // },
      {
        title: 'Classes',
        href: '/app/admin/academics',
        icon: 'mdi:college'
      },
      {
        title: 'Attendance',
        href: '/app/admin/attendanceNew',
        icon: 'fluent:checkbox-person-16-regular'
      },
      // {
      //   title: 'Calendar',
      //   href: '/app/admin/timetable',
      //   icon: CalendarMonthIcon
      // },
      // {
      //   title: 'Profile',
      //   href: '/app/admin/profile',
      //   icon: 'bi:card-text'
      // },
      // {
      //   title: 'Reports',
      //   href: '/app/admin/AllReports',
      //   icon: AssessmentOutlinedIcon
      // },
      {
        title: 'More',
        href: '/app/admin/more',
        icon: 'tabler:list'
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      {items.map(item => {
        const key = item.title + depth;

        if (item.items) {
          const open = matchPath(pathname, {
            path: `${item.href}/*`,
            exact: false
          });

          return (
            <NavItem
              depth={depth}
              icon={item.icon}
              info={item.info}
              key={key}
              open={Boolean(open)}
              title={item.title}
            >
              {renderNavItems({
                depth: depth + 1,
                pathname,
                items: item.items
              })}
            </NavItem>
          );
        } else {
          return (
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              info={item.info}
              key={key}
              title={item.title}
            />
          );
        }
      })}
    </Box>
  );
}


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: '100%',
    height: 70,
    position: 'fixed',
    bottom: 0,
    left: 0
  },
  desktopDrawer: {
    width: '100%',
    height: 85,
    borderTop: 'none',
    position: 'fixed',
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.06)',
    paddingTop: 0,
    bottom: 0,
    left: 0
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Drawer
        anchor="bottom"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
        style={{ overflowY: 'hidden' }}
      >
        <Box
          // height="100%"
          display="flex"
          style={{ overflowY: 'hidden' }}
          flexGrow={1}
          p={0}
          m={0}
          marginTop={-1}
          mpaddingBottom={-1}
          justifyContent="center"
          flexDirection="row"
        >
          {sections.map(section => (
            <List
              key={section.subheader}
              style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
              length={sections.length}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
