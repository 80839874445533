import React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, fade } from '@material-ui/core';
import { Icon } from '@iconify/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Rules from 'src/Rules.json'
import { Tooltip } from '@mui/material';
const matchColor = color => {
  const map = {
    blue: {
      backgroundColor: Rules.nav_top_bar.navbar_background_color,
      hover: fade(Rules.nav_top_bar.navbar_background_color, 0.8)
    },
    lightblue: {
      backgroundColor: '#d1eaff',
      hover: fade('#d1eaff', 0.8)
    },
    green: {
      backgroundColor: '#2e7d32',
      hover: fade('#2e7d32', 0.8)
    },
    black: {
      backgroundColor: '#000000',
      hover: fade('#000000', 0.8)
    },
    grey: {
      backgroundColor: '#808080',
      hover: fade('#808080', 0.8)
    },
    red: {
      backgroundColor: '#c62828',
      hover: fade('#c62828', 0.8)
    },
    yellow: {
      backgroundColor: '#fbc02d',
      hover: fade('#fbc02d', 0.8)
    }
  };
  return (
    map[color] || {
      backgroundColor: color || '#002F6D',
      hover: fade(color || '#002F6D', 0.8)
    }
  );
};

const matchSize = size => {
  const map = {
    small: {
      paddingRight: '10px',
      paddingLeft: '10px',
      paddingTop: '3px',
      paddingBottom: '3px',
      iconSize: '16px',
      loadingSize: 16,
      iconPadding: '3px'
    },
    medium: {
      paddingRight: '12px',
      paddingLeft: '12px',
      paddingTop: '5px',
      paddingBottom: '5px',
      iconSize: '20px',
      loadingSize: 17,
      iconPadding: '5px'
    },
    large: {
      paddingRight: '14px',
      paddingLeft: '14px',
      paddingTop: '7px',
      paddingBottom: '7px',
      iconSize: '22px',
      loadingSize: 20,
      iconPadding: '5px'
    }
  };
  return map[size] || map['medium'];
};
const CustomIconButton = ({
  startIcon,
  endIcon,
  size,
  disabled,
  onClick,
  label,
  color,
  variant,
  icon,
  options,
  target,
  href,
  loading,
  type,
  tooltip = '',
  placement = '',
  ...props
}) => {
  const size1 = matchSize(size);
  const color1 = matchColor(color);
  const disabled1 = disabled || loading;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClickNew = () => {
    if (options) {
      handleToggle();
    } else {
      if (onClick) onClick();
    }
  };

  return (
    <>
      <Tooltip title = {tooltip ? tooltip : ''} placement = {placement ? placement : ''}>
      <Button
        ref={anchorRef}
        // {...props}
        href={href}
        target={target}
        disabled={disabled1}
        variant={variant}
        onClick={handleClickNew}
        size={size}
        type={type}
        sx={{
          backgroundColor: disabled1
            ? ''
            : variant == 'contained'
            ? color1.backgroundColor
            : '',
          '&:hover': {
            backgroundColor: disabled1
              ? ''
              : variant == 'contained'
              ? color1.hover
              : '',
            borderColor: disabled1
              ? ''
              : variant === 'no-border'
              ? ''
              : color1.backgroundColor,
            border:
              variant === 'contained'
                ? ''
                : variant === 'no-border'
                ? ''
                : `1px solid ${color1.backgroundColor}`
          },
          borderColor: disabled1 ? '' : variant == 'outlined' ? '#9e9e9e' : '',

          color: disabled1
            ? ''
            : variant == 'outlined'
            ? color1.backgroundColor
            : variant == 'text'
            ? color1.backgroundColor
            : '',
          // paddingRight: size1.paddingRight,
          // paddingLeft: size1.paddingLeft,
          paddingTop: size1.paddingTop,
          paddingBottom: size1.paddingBottom,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none'
        }}
        loadingPosition="start"
      >
        {startIcon == null && endIcon == null && loading && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              paddingRight: size1.iconPadding
            }}
          >
            <CircularProgress color="disabled" size={size1.loadingSize} />
          </Box>
        )}
        {startIcon && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              paddingRight: size1.iconPadding
            }}
          >
            {loading ? (
              <CircularProgress color="disabled" size={size1.loadingSize} />
            ) : (
              <Icon icon={startIcon} style={{ fontSize: size1.iconSize }} />
            )}
          </Box>
        )}

        {label && <span>{label}</span>}

        {icon && <Icon icon={icon} style={{ fontSize: size1.iconSize }} />}

        {endIcon && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              paddingLeft: size1.iconPadding
            }}
          >
            {loading ? (
              <CircularProgress color="disabled" size={size1.loadingSize} />
            ) : (
              <Icon icon={endIcon} style={{ fontSize: size1.iconSize }} />
            )}
          </Box>
        )}
      </Button>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 20000
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'right bottom'
            }}
          >
            <Paper style={{zIndex:200000,position:'sticky'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={option} onClick={() => option.onClick()}>
                      {option.icon && (
                        <>
                          <Icon
                            icon={option.icon}
                            style={{ fontSize: '20px' }}
                          />
                          <Box mr={1} />
                        </>
                      )}
                      {option.option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default CustomIconButton;
