import ChangePassword from 'src/views/auth/LoginView/Change_Password';
import React, { useRef, useState, useEffect, useCallback } from 'react';

import {
  Box,
  CardHeader,
  SvgIcon,
  Hidden,
  Dialog,
  Typography,
  makeStyles,
  withStyles,
  IconButton,
  Divider
} from '@material-ui/core';
import {
  PlusCircle as PlusCircleIcon,
  MoreVertical as MoreIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  MousePointer
} from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CheckCircle, ChevronDown } from 'react-feather';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Settingsclient from 'src/utils/GQLSettingsClient';
import useYear from 'src/hooks/useYear';
import Skeleton from '@material-ui/lab/Skeleton';
import Rules from 'src/Rules.json';
import AddAcademicYear from 'src/views/InstSetup/AcademicYear/AddAcademicYear';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Icon } from '@iconify/react';
import Avatar from '@mui/material/Avatar';
import AcademicYearAction from 'src/views/InstSetup/AcademicYear/AcademicYearAction';
import DeleteAcademicYear from 'src/views/InstSetup/AcademicYear/DeleteAcademicYear'

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: Rules.nav_top_bar.topbar_selection_background_color,
    borderRadius: '12px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 5,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 2,
    paddingLeft: 3,
    borderRadius: '12px',
    backgroundColor: Rules.nav_top_bar.topbar_selection_background_color,
    '&:hover': {
      backgroundColor: Rules.nav_top_bar.topbar_selection_background_color
    }
  }
}));
const GreenTextTypography = withStyles({
  root: {
    color: '#7cb342'
  }
})(Typography);

const noPointer = { cursor: 'pointer' };
const RedTextTypography = withStyles({
  root: {
    color: '#f44336'
  }
})(Typography);
const Account = () => {
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();
  const { setYear, year } = useYear();

  const ref = useRef(null);
  const { user, logout } = useAuth();
  const [isOpen, setOpen] = useState(false);
  console.log(user);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen1] = useState(false);
  const handleClickOpen = async data => {
    try {
      handleClose();
      await setYear(
        'human resource management service',
        'Academic Year',
        data._id
      );
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };
  const handleClickClose = () => {
    setOpen1(false);
  };
  const [loading, setLoading] = useState(true);
  const [academicYears, setAcademicYears] = useState([]);
  const getAcademicYears = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          get_all_academic_years {
            year_name
            _id
            status
            term_type
            start_date
            end_date
            stream_code
          }
        }
      `;

      const { data } = await Settingsclient.query({
        query: getstudents,
        variables: {},
        fetchPolicy: 'network-only'
      }).then(res => {
        console.log(res);
        return res;
      });

      if (isMountedRef.current) {
        setAcademicYears(data.get_all_academic_years);
        const active = data.get_all_academic_years.find(
          x => x.status == 'active'
        );
        console.log(active);
        // setTermType(active.term_type)
        // setTimeRange(active.year_name)
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getAcademicYears();
  }, [getAcademicYears]);

  const [open1, setOpen11] = useState(false);
  const handleClickOpen1 = async () => {
    setOpen11(true);
  };
  const handleClickClose1 = () => {
    setOpen11(false);
  };

  const [deleteId, setDeleteId] = useState({});
  const [deleteopen, setDeleteOpen] = useState(false);
  const handleClickDeleteOpen = async (
    event,
    academicyear_id,
    academicyear_startdate,
    academicyear_enddate,
    academicyear_status,
    academicyear_streamcode,
    academicyear_yearname
  ) => {
    setDeleteId({
      academicyear_id,
      academicyear_startdate,
      academicyear_enddate,
      academicyear_status,
      academicyear_streamcode,
      academicyear_yearname
    });
    setDeleteOpen(true);
  };
  const handleClickDeleteClose = () => {
    setDeleteOpen(false);
  };

  const [editId, setEditId] = useState({});
  const [editopen, setEditOpen] = useState(false);
  const handleClickEditOpen = async (
    event,
    academicyear_id,
    academicyear_startdate,
    academicyear_enddate,
    academicyear_status,
    academicyear_streamcode,
    academicyear_yearname
  ) => {
    setEditId({
      academicyear_id,
      academicyear_startdate,
      academicyear_enddate,
      academicyear_status,
      academicyear_streamcode,
      academicyear_yearname
    });
    setEditOpen(true);
  };
  const handleClickEditClose = () => {
    setEditOpen(false);
  };

  const [actionId, setActionId] = useState({});
  const [actionopen, setActionOpen] = useState(false);
  const handleClickActionOpen = async (
    event,
    academicyear_id,
    academicyear_startdate,
    academicyear_enddate,
    academicyear_status,
    academicyear_streamcode,
    academicyear_yearname
  ) => {
    setActionId({
      academicyear_id,
      academicyear_startdate,
      academicyear_enddate,
      academicyear_status,
      academicyear_streamcode,
      academicyear_yearname
    });
    setActionOpen(true);
  };
  const handleClickActionClose = () => {
    setActionOpen(false);
  };
  return (
    <>
      {year ? (
        <CardHeader
          className={classes.item}
          style={noPointer}
          title={
            <Box
              display="flex"
              // alignItems="left"
              // component={ButtonBase}
              onClick={handleOpen}
              ref={ref}
              // className={classes.item}
            >
              <Box>
                <Box display="flex">
                  <Box mr={0.5} />

                  <Typography
                    variant="h6"
                    textAlign="left"
                    color={Rules.nav_top_bar.typography_topbar_heading}
                  >
                    <Hidden smDown> Academic Year</Hidden> {year.year_name}
                  </Typography>

                  <Box mr={0.5} />
                  <Hidden smDown>
                    {year ? (
                      <SvgIcon cursor="pointer">
                        <ChevronDown
                          strokeWidth="2px"
                          size="20px"
                          color={Rules.nav_top_bar.topbar_menu_button_color}
                        />
                      </SvgIcon>
                    ) : (
                      <></>
                    )}
                  </Hidden>
                </Box>
              </Box>
            </Box>
          }
        ></CardHeader>
      ) : (
        <Box mr={2}>
          <Skeleton width={120} height={60} />
        </Box>
      )}
      {/* </Card> */}

      {year && (
        <Menu
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
          PaperProps={{
            elevation: 5,
            sx: {
              minWidth: '250px',
              padding: '10px 18px 8px 18px',
              borderRadius: '8px',
              '& .MuiMenuItem-root': {
                borderRadius: '12px',
                backgroundColor:
                  Rules.nav_top_bar.topbar_selection_background_color,
                marginBottom: 1,
                paddingTop: user.user_group === 'owner' ? 0.3 : 1.1,
                paddingBottom: user.user_group === 'owner' ? 0.3 : 1.1,
                paddingLeft: 1.6,
                paddingRight: 1.6,

                minWidth: 100
              },

              // Removed drop-shadow filter here
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                // bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {academicYears.map(x => (
            <MenuItem className={classes.menuItem}>
              <Box
                display="flex"
                width="100%"
                alignContent={'center'}
                alignItems={'center'}
              >
                {x.year_name == year.year_name && x.status === 'active' && (
                  <Avatar
                    sx={{
                      ml: -1,
                      bgcolor: '#81c784',
                      height: '8px',
                      width: '8px'
                    }}
                    variant="circle"
                  >
                    <Box></Box>
                  </Avatar>
                )}
                <Box mr={0.5} />
                <Box
                  width="100%"
                  display="flex"
                  onClick={e => handleClickOpen(x)}
                >
                  <Typography variant="h5">{x.year_name}</Typography>
                  <Box flexGrow={1} />
                  {x.year_name == year.year_name && (
                    <CheckCircle
                      size="20px"
                      strokeWidth="3px"
                      color={Rules.nav_top_bar.topbar_selection_icon_color}
                    />
                  )}
                  {/* <Box mr={1} /> */}
                </Box>
                {user.user_group === 'owner' && (
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {popupState => (
                      <div>
                        <Box {...bindTrigger(popupState)} mt={0.7} mr={-1}>
                          {/* <SvgIcon fontSize="small">
                              <MoreIcon fontSize="small"/>
                              </SvgIcon> */}
                          <Icon
                            icon={'mingcute:more-2-line'}
                            style={{ fontSize: '24px' }}
                          />
                        </Box>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {/* <MenuItem 
                                              onClick={(event) => handleClickEditOpen(event ,
                                                x._id,
                                                x.start_date,
                                                x.end_date,
                                                x.status,
                                                x.stream_code,
                                                x.year_name
                                                )}
                                              >
                                              
                                              <Box mr={1} />
                                              <Typography variant = "body2">
                                              EDIT
                                              </Typography>
                                              </MenuItem>
                                              <Divider /> */}
                          {x.status === 'active' && (
                            <MenuItem
                              onClick={event =>
                                handleClickActionOpen(
                                  event,
                                  x._id,
                                  x.start_date,
                                  x.end_date,
                                  x.status,
                                  x.stream_code,
                                  x.year_name
                                )
                              }
                            >
                              <Box mt={1}>
                              <RedTextTypography variant="body2">
                                DE-ACTIVATE
                              </RedTextTypography></Box>
                            </MenuItem>
                          )}
                          {x.status === 'inactive' && (
                            <MenuItem
                              onClick={event =>
                                handleClickActionOpen(
                                  event,
                                  x._id,
                                  x.start_date,
                                  x.end_date,
                                  x.status,
                                  x.stream_code,
                                  x.year_name
                                )
                              }
                            >
                               <Box mb={1}>
                              <GreenTextTypography>
                                ACTIVATE
                              </GreenTextTypography>
                              </Box>
                            </MenuItem>
                            
                          )}
                          <Divider />
                          <MenuItem
                            onClick={event =>
                              handleClickDeleteOpen(
                                event,
                                x._id,
                                x.start_date,
                                x.end_date,
                                x.status,
                                x.stream_code,
                                x.year_name
                              )
                            }
                          >
                            <RedTextTypography variant="body2">
                              DELETE
                            </RedTextTypography>
                          </MenuItem>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                )}
              </Box>
            </MenuItem>
          ))}
          {user.user_group === 'owner' && (
            <Box
              mt={1}
              display={'flex'}
              style={{ cursor: 'pointer' }}
              onClick={handleClickOpen1}
              mt={1}
            >
              <Box mr={1} />
              <Typography variant="body1" color="textSecondary">
                + Add
              </Typography>
            </Box>
          )}
        </Menu>
      )}
      <Dialog maxWidth="sm" fullWidth onClose={handleClickClose} open={open}>
        <ChangePassword open={handleClickClose} />
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleClickClose1} open={open1}>
        <AddAcademicYear open={handleClickClose1} />
      </Dialog>

      <Dialog
          maxWidth="sm"
          fullWidth
          //onExit = {getStreams}
          onClose={handleClickDeleteClose}
          open={deleteopen}
        >
          <DeleteAcademicYear  id = {deleteId}  open={handleClickDeleteClose} />
          
        </Dialog>
        <Dialog
          maxWidth="sm"
          fullWidth
          //onExit = {getStreams}
          onClose={handleClickActionClose}
          open={actionopen}
        >
          <AcademicYearAction id = {actionId}  open={handleClickActionClose}  />
        </Dialog>
    </>
  );
};

export default Account;
