import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const SetingsGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();
  console.log(user);



    if(user.user_group === "faculty"||user.user_group === "program_coordinator"  ){
        return <Redirect to="/accessdenied" />;
      }
     
  //if (isAuthenticated && message.data.message == "NEW_PASSWORD_REQUIRED") {

  //return <Redirect to="/app/account/reset_password"/>;
  // }

  return <>{children}</>;
};

SetingsGuard.propTypes = {
  children: PropTypes.node
};

export default SetingsGuard;
