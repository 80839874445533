import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 500,
    MaxHeight: 800
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  statusField: {
    fontSize: 16,
    color: 'textPrimary',
    textDecoration: 'none'
    // variant:"h3"
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton component={RouterLink}
              to={{
                pathname: `/app/admin/settings/user/user_management`
              }} style={{ color: '#616161' }} ref={ref} >
          <SvgIcon>
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      
    </>
  );
};

export default Notifications;
