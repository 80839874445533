
import getName from 'src/utils/getName';
import ChangePassword from 'src/views/auth/LoginView/Change_Password';
import React, { useRef, useState } from 'react';
import {
  Box,
  CardHeader,
  SvgIcon,
  Hidden,
  Dialog,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CheckCircle, ChevronDown } from 'react-feather';
import Skeleton from '@material-ui/lab/Skeleton';
import useTerm from 'src/hooks/useTerm';
import Rules from 'src/Rules.json';
import useStream from 'src/hooks/useStream';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 35,
    width: 35,
    marginRight: theme.spacing(1)
  },
  // popover: {
  //   width: 200
  // },
  menuItem: {
    color: Rules.nav_top_bar.dark,
    borderRadius: '12px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 5,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 2,
    paddingLeft: 3,
    borderRadius: '12px',
    backgroundColor: Rules.nav_top_bar.topbar_selection_background_color,
    '&:hover': {
      backgroundColor: Rules.nav_top_bar.topbar_selection_background_color
    }
  }
    // alignItems: 'center'
  
}));
const noPointer = { cursor: 'pointer' };

const Account = () => {
  const { setTerm, term } = useTerm();
const {stream} = useStream();
// if(stream.stream_code&&stream.stream_code.toLowerCase().startsWith("puc")){
//   setTerm('odd');
// }
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { user, logout } = useAuth();;
  console.log(user);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen1] = useState(false);
  const handleClickOpen = async data => {
    try {
      handleClose();
      await setTerm('human resource management service', 'Term Type', data);
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };
  const handleClickClose = () => {
    setOpen1(false);
  };

  return (
    <>
     {(stream)&&
     term ? ( <CardHeader
        className={classes.item}
        style={noPointer}
        title={
          <Box display="flex" onClick={handleOpen} ref={ref}>
            <Box>
              <Box display="flex">
                <Box mr={0.5} />
                
                  <Typography variant="h6" textAlign="left" color={Rules.nav_top_bar.typography_topbar_heading}>
                    {getName(term)}
                  </Typography>
               
                <Box mr={0.5} />
                <Hidden smDown>
                  {term && (
                    <SvgIcon cursor="pointer">
                      <ChevronDown
                        strokeWidth="2px"
                        size="20px"
                        color={Rules.nav_top_bar.topbar_menu_button_color}
                      />
                    </SvgIcon>
                  )}
                </Hidden>
              </Box>
            </Box>
          </Box>
        }
      ></CardHeader> ) : (
        <Skeleton width={60} height={60} mr={2} />
      )}

      <Menu
        onClose={handleClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '140px',
            padding: '10px 18px 8px 18px',
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              backgroundColor: Rules.nav_top_bar.topbar_selection_background_color,
              marginBottom: 1,
              // padding: 1.8,
              paddingTop:1.1,
              paddingBottom: 1.1,
              paddingLeft: 1.6,
              paddingRight: 1.6,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

{<> <MenuItem
 className={classes.menuItem}
 onClick={e => handleClickOpen('all')}
>
 <Box display='flex' width='100%'>
   <Typography variant="h5">All</Typography>
   <Box flexGrow={1}/>
   {term=='all'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

    {/* <Label color="success">Active</Label>  */}
 </Box>
</MenuItem> <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('odd')}
        >
          <Box display='flex' width='100%'>
            <Typography variant="h5">Odd</Typography>
            <Box flexGrow={1}/>
            {term=='odd'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

            {/* <Label color="success"> <Typography variant="caption">Active</Typography></Label> */}
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('even')}
        >
          <Box display='flex' width='100%'>
            <Typography variant="h5">Even</Typography>
            <Box flexGrow={1}/>
            {term=='even'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

            {/* <Label color="error">InActive</Label> */}
          </Box>
        </MenuItem></>}
        {/* <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('all')}
        >
          <Box display='flex' width='100%'>
            <Typography variant="h5">All</Typography>
            <Box flexGrow={1}/>
            {term=='all'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

          </Box>
        </MenuItem> */}
        {/* <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('odd')}
        >
          <Box display='flex' width='100%'>
            <Typography variant="h5">Odd</Typography>
            <Box flexGrow={1}/>
            {term=='odd'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('even')}
        >
          <Box display='flex' width='100%'>
            <Typography variant="h5">Even</Typography>
            <Box flexGrow={1}/>
            {term=='even'&&<CheckCircle size='20px' strokeWidth='3px' color={Rules.nav_top_bar.topbar_selection_icon_color} />}

          </Box>
        </MenuItem> */}
      </Menu>
      <Dialog maxWidth="sm" fullWidth onClose={handleClickClose} open={open}>
        <ChangePassword open={handleClickClose} />
      </Dialog>
    </>
  );
};

export default Account;
