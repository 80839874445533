import React, { useRef, useState, useEffect, useCallback } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Hidden,
  CardHeader,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { ChevronDown, CheckCircle } from 'react-feather';
import client from 'src/utils/GQLSettingsClient';
import useAuth from 'src/hooks/useAuth';
import useStream from 'src/hooks/useStream';
import Rules from 'src/Rules.json';
import fetchDocument from 'src/components/get_document_image';

const useStyles = makeStyles(theme => ({
  avatar: {
    //   height: 35,
    //   width: 35,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  menuItem: {
    color: Rules.nav_top_bar.topbar_background_color,
    borderRadius: '12px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 0,
    padding: 0
    // borderRadius: '12px',
    // '&:hover': {
    //   // backgroundColor: theme.palette.background.dark
    // }
    // alignItems: 'center'
  }
}));
const noPointer = { cursor: 'pointer' };

const Account = load => {
  const { user } = useAuth();

  const { setStream, stream, image } = useStream();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  console.log('streamselected', stream);
  const handleOpen = () => {
    setOpen(true);
  };

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const [settings, setSettings] = useState([{}, {}]);


  const handleClickOpen = async data => {
    try {
      handleClose();

      await setStream('human resource management service', 'stream', data._id);
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };

  function search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].applicantId === nameKey) {
        return myArray[i];
      }
    }
  }
  const [ImageList, setImageList] = useState([]);
  const s3 = new AWS.S3();

  const getStreams = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          get_streams {
            _id
            stream_code
            stream_name
            institution {
              address {
                city
                country
                postal_code
                state
                street
                street_2
              }
              contact {
                fax
                mobile
                phone
                website
                secondary_email
              }
              institution_name
              logo_url
              director_name
              principal_name
              short_name
            }
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,

          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log('stream', res);

          return res;
        });

      if (isMountedRef.current) {
        setSettings(data.get_streams);
        const value = data.get_streams.find(x => x._id == stream.session_value);
        setLoading(false);
        console.log(data.get_streams);

        data.get_streams.map(async customer => {
          if (
            customer.institution &&
            !(customer.AddOutlinedIcon_id === null || undefined)
          ) {
            let bucketName = 'erpinstitution';

            var photoKey = customer.institution.logo_url;
            const img = await fetchDocument('logo', photoKey);
console.log(img)
            if (img === undefined) {
              setImageList(oldArray => [
                ...oldArray,
                {
                  applicantId: customer._id,
                  Image: null
                }
              ]);
            } else {
              var base64Flag = `data:${img.contentType};base64,`;
              var imgData = _arrayBufferToBase64(img.Body);

              setImageList(oldArray => [
                ...oldArray,
                {
                  applicantId: customer._id,
                  Image: img
                }
              ]);
            }
          } else {
            setImageList(oldArray => [
              ...oldArray,
              {
                applicantId: customer._id,
                Image: null
              }
            ]);
          }
        });
      }
    } catch (err) {
      console.error('strream', err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getStreams();
  }, [getStreams]);

  // console.log('user', stream.institution);
  return (
    <>
      <CardHeader
        className={classes.item}
        style={noPointer}
        title={
          <Box
            display="flex"
            // alignItems="left"
            // component={ButtonBase}
            onClick={handleOpen}
            ref={ref}
            // className={classes.item}
          >
            {(stream && ImageList.length>0) ? (
              <Avatar src={ImageList.find(t=>t.applicantId===stream._id)&&ImageList.find(t=>t.applicantId===stream._id).Image} className={classes.avatar}></Avatar>
            ) : (
              <Skeleton variant="circle" width={40} height={40} />
            )}
            <Hidden smDown>
              <Box ml={0.5}>
                <Box display="flex">
                  {stream ? (
                    <Typography
                      variant="h6"
                      textAlign="left"
                      color={Rules.nav_top_bar.typography_topbar_heading}
                    >
                      {stream.institution
                        ? stream.institution.institution_name
                        : ''}
                    </Typography>
                  ) : (
                    <Skeleton width={120} />
                  )}
                  <Box mr={1} />
                  {stream ? (
                    user.user_group !== 'faculty' && (
                      <SvgIcon cursor="pointer">
                        <ChevronDown
                          strokeWidth="2px"
                          size="20px"
                          color={Rules.nav_top_bar.topbar_menu_button_color}
                        />
                      </SvgIcon>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
                <Box display="flex" mt={-0.2}>
                  {stream ? (
                    <Typography
                      variant="body2"
                      textAlign="left"
                      // color="inherit"
                      color={Rules.nav_top_bar.typography_topbar_sub_heading}
                    >
                      {stream.stream_code ? stream.stream_code : ''} -{' '}
                      {stream.stream_name ? stream.stream_name : ''}
                    </Typography>
                  ) : (
                    <Skeleton width={80} />
                  )}
                </Box>
              </Box>
            </Hidden>
          </Box>
        }
      ></CardHeader>
      {user.user_group !== 'faculty' && (
        <Menu
          onClose={handleClose}
          keepMounted
          PaperProps={{
            elevation: 5,
            sx: {
              minWidth: '250px',
              padding: '10px 18px 8px 18px',
              borderRadius: '12px',
              '& .MuiMenuItem-root': {
                borderRadius: '12px',
                backgroundColor: Rules.nav_top_bar.topbar_selection_background_color,
                marginBottom: 1,
                padding: 1.8,
                minWidth: 100
              },
              maxHeight: '600px',
              overflow: 'auto',

              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          {settings.map(data => {
            let img;
            if (search(data._id, ImageList)) {
              img = search(data._id, ImageList).Image;
            }
            console.log(img)

            return (
              <MenuItem
                className={classes.menuItem}
                onClick={e => handleClickOpen(data)}
                // style={{ padding: '21px' }}
              >
                <Box display="flex" width="100%" onClick={handleOpen}>
                  <Box display="flex">
                    <Avatar src={img} className={classes.avatar}></Avatar>
                    <Hidden smDown>
                      <Box ml={0.5}>
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            textAlign="left"
                            color={Rules.nav_top_bar.typography_topbar_heading}
                          >
                            {data.institution
                              ? data.institution.institution_name
                              : ''}
                          </Typography>

                          <Box mr={1} />
                        </Box>
                        <Box display="flex" mt={-0.5}>
                          <Typography
                            variant="body2"
                            textAlign="left"
                            // color="inherit"
                            color={Rules.nav_top_bar.typography_topbar_sub_heading}
                          >
                            {data.stream_code ? data.stream_code : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Hidden>
                  </Box>

                  <Box flexGrow={1} />
                  <Box mr={1} />
                  {data.stream_code == stream.stream_code && (
                    <CheckCircle
                      size="20px"
                      strokeWidth="3px"
                      color={Rules.nav_top_bar.topbar_selection_icon_color}
                    />
                  )}
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default Account;
