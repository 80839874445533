import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Divider,Typography, Box, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Icon } from '@iconify/react';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    // padding: '8px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    textAlign: 'center'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
   
    borderTopWidth:2,
    // padding: '8px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    // textAlign: 'left'
    textAlign: 'center',
    display: 'flex', // add display flex to the button
    alignItems: 'center' // align items to center
  },
  root2: {
 
    width: '100%',
    height: 2,
    backgroundColor:'#002250',
    marginTop: 0,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 25
    // marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    fontSize: 5
  },
  active: {
    color: '#002250',
    borderTop: `2px solid #002250` ,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'center',
      color: '#002250',
      fontWeight: 'bold', 
      fontSize: 25 
    },
    background: theme.palette.background.dark,
    // borderRadius: 50
  },
  caption:{
    fontSize:13,
    fontWeight:500
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: NavIcon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let padding = 8;

  if (depth > 0) {
    padding = 32 + 8 * depth;
  }

  const style = { padding };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >

        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="30" />}
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        // exact
        // style={style}
        to={href}
        elevation={10}
      >
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" pt={1} pb={1}>        
          {NavIcon && (
            // <Icon
            //   className={classes.icon}
            //   // fontSize="large"
            // />
            <Icon
            icon={NavIcon}
            style={{
              fontSize: '28px',
              marginBottom: '2px'
            }}
          />
          )}
          {/* <Box mt={0.1}/> */}
          <Typography variant="caption" className={classes.caption}>
          {title}
    </Typography>
          {/* <span className={classes.title}>
          {title}
        </span> */}
        </Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
