import React, { useState ,useEffect,useCallback} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Link,
  SvgIcon,
  createMuiTheme,
  withStyles,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import wait from 'src/utils/wait';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { useHistory} from 'react-router-dom';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import moment from 'moment';
import gql from 'graphql-tag';
import client from '../../../utils/GQLSettingsClient';
import theme2 from 'src/utils/ButtonTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import SubmitButton from 'src/components/Button/RoundBlue';
import Button from 'src/components/Button/CustomButton';

  const useStyles = makeStyles(theme => ({

    root: {
      marginRight :10
    },
}));


const AddAccreditation = ({ getStreams , handleSubmit,open,id, submitting,accreditationinfo }) => {
  const [isAlertVisible, setAlertVisible] = useState(true);
  const classes = useStyles();
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  console.log(id)

  const [loading , setLoading] = useState(true)
  const [check,setCheck] = useState();
  const getCustomers = useCallback(async () => {
    try {
      const getstudents = gql`
query MyQuery {
  check_for_academic {
    message
    
  }
}
`;

      const { data } = await 
      client.query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });
      
        
       
       if (isMountedRef.current) {

       
        setCheck(data.check_for_academic.message);
        setLoading(false)
        console.log(data.check_for_academic.message);
        
       }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

 
 
  
  return (
    <Formik
      initialValues={{

        submit: null
      }}
      validationSchema={Yup.object().shape({
       
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // NOTE: Make API request
       
      
         await wait(1000)
          try {
            const ADD = gql`
            mutation delete_academic_year(
              $_id: String!
             
    
            ) {
              delete_academic_year(
              _id                : $_id
               
        
              ) {
            _id
              }
            }
           
          `;
            const { data } = await 
            client.mutate({
                mutation: ADD,
                
                variables: {
                  _id:        id.academicyear_id,
          
      
                },
      
              })
              .then(res => {
                console.log(res)
               open()
              //  getStreams()
                  enqueueSnackbar('Academic Year Deleted', {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                  },
                    variant: 'success'
                  })
              
                return res;
              });
    
              window.location.reload();
      
            
          } catch (err) {
            enqueueSnackbar(err.message.slice(14), {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
              variant: 'error'
            })
            console.log(err.message);
            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }
        
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
        
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Card>
          <CardHeader title="Delete Academic Year" />
          <Divider />
          {loading ? ( <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress />
              </Box>) : (

 <Box>
   
    {check === "OPERATION_DENIED" ? (
       <Box >
          <Box justifyContent = "center" align = "center" alignItems= 'center' display =  'flex' flexDirection = "column" minHeight = {150} minWidth = {150}>
          <SvgIcon fontSize = "large">
          <PeopleOutlineRoundedIcon />
          </SvgIcon>
       
          <Typography>
            You dont have an access to delete Academic Year 
          </Typography>
      
        </Box>
      
         <Box  mr = {2} mb ={2} display = "flex">
                        <Box flexGrow = {1} />
                       
                          <MuiThemeProvider theme={theme2}>
                          <Button
                          color="primary"
                         
                          size="large"
                              onClick = {open}
                          variant="contained"
                          className={{ disabled: classes.root1 }}
                        >
                           
                          OK
                        </Button>
                        </MuiThemeProvider>
                       
                        
                        
                        </Box>
                      </Box>
      ) : (
         
              <form  noValidate onSubmit={handleSubmit}>
<Box px={2} py={1.5} style={{ backgroundColor: '#fafafa' }}>
                <CardContent>
     
                <Box
         display="flex"
         alignItems="center"
         m="1%"
        >
          <SvgIcon fontSize="medium">
                <ReportProblemOutlinedIcon />
                </SvgIcon>
          <Box ml={2}
          >
              
          <Typography
            
              color="textPrimary"
              
              variant="h5"
            >
             {`Are you sure you want to  delete Academic Year  ${id.academicyear_yearname}?`}
            </Typography>
       
            </Box>
          </Box>
          </CardContent>
        </Box> 
        <Divider />
          <Box px={2} py={1.5} height="auto" display = "flex">
                  <Box flexGrow = {1} />
                  <>
                  <Button
              size="medium"
              color="blue"
              onClick={open}
              variant="outlined"
              label={'Cancel'}
            />
            <Box mr={1.5} />
            <SubmitButton
                   size="medium"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting && (<CircularProgress className = {classes.root}
                                        color = "secondary"
                                        size={20}
                                      />
                      )}
                    DELETE
                  </SubmitButton>
                  </>
                 
                </Box>
              </form>
          )}
          
                   </Box>
                       )}
        
        </Card>
        </PerfectScrollbar>
      )}
    </Formik>
  );
};

AddAccreditation.protoTypes = {
  className: PropTypes.string,
  institutecontact : PropTypes.object.isRequired,
  instituteaddress : PropTypes.object.isRequired,
  open : PropTypes.func
}
AddAccreditation.defaultProps = {
  open : () => {},
  institutecontact: () => {},
  instituteaddress : () => {},

}

export default AddAccreditation;
 
