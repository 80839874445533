import React from 'react';
import { makeStyles ,Typography , Grid} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return <Page className={classes.root} title="Home">
      <Grid container>
        <Grid item md = {6}>
            
        </Grid>
      </Grid>
  </Page>;
};

export default HomeView;
