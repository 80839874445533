import React, { useState ,useEffect,useCallback} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import wait from 'src/utils/wait';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { useHistory} from 'react-router-dom';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import moment from 'moment';
import gql from 'graphql-tag';
import client from '../../../utils/GQLSettingsClient';
import theme from 'src/utils/ButtonTheme';
import {createMuiTheme,ThemeProvider } from '@material-ui/core';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import SubmitButton from 'src/components/Button/RoundBlue';
import Button from 'src/components/Button/CustomButton';


const useStyles = makeStyles(theme => ({

    root: {
      marginRight :10
    },
}));


const UserAction = ({ getStreams , handleSubmit,open,id, onedit,submitting,accreditationinfo }) => {
  const [isAlertVisible, setAlertVisible] = useState(true);

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const [loading , setLoading] = useState(true)
  const [check,setCheck] = useState();
  const getCustomers = useCallback(async () => {
    try {
      const getstudents = gql`
query MyQuery {
  check_for_academic {
    message
    
  }
}
`;

      const { data } = await 
      client.query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });
      
        
       
       if (isMountedRef.current) {

       
        setCheck(data.check_for_academic.message);
        setLoading(false)
        console.log(data.check_for_academic.message);
        
       }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

 
  
  return (
    <Formik
      initialValues={{

        submit: null
      }}
      validationSchema={Yup.object().shape({
       
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // NOTE: Make API request

          if(id.academicyear_status === "active"){
            await wait(1000)
            try {
            const ADD = gql`
                mutation update_academic_year(
                  $_id: String!
                  $status : String!
  
                ) {
                    update_academic_year(
                    _id                : $_id
                    status                : $status
      
                  ) 
                  {
                    _id
                    
                  }
                }
               
              `;
             
                const { data } = await 
                client.mutate({
                    mutation: ADD,
                    
                    variables: {
                      _id:        id.academicyear_id,
                 
    
                      status : "inactive"
                    },
                   
                  })
                  .then(res => {
                    console.log(res)
                  
                   open()
                   enqueueSnackbar(`Academic Year De-activated`, {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                  },
                    variant: 'success'
                  })
                    return res;
                  });
                  window.location.reload();
            
          
                
              } catch (err) {
                enqueueSnackbar(err.message.slice(14), {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                  variant: 'error'
                })
                console.log(err.message);
                setStatus({ success: false });
                setErrors({ submit: err });
                setSubmitting(false);
              }
            }
            if(id.academicyear_status === "inactive"){
              await wait(1000)
              try {
              const ADD = gql`
                  mutation update_academic_year(
                    $_id: String!
                    $status : String!
             
                  ) {
                    update_academic_year(
                      _id                : $_id                  
                      status                : $status
  
              
                    ) 
                    {
                      _id
                    
                    }
                  }
                 
                `;
               
                  const { data } = await 
                  client.mutate({
                      mutation: ADD,
                      
                      variables: {
                        _id:        id.academicyear_id,
                   
      
                        status : "active"
                      },
                     
                    })
                    .then(res => {
                      console.log(res)
                     open()
                     getStreams()
                     enqueueSnackbar(`Academic Year Activated`, {
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                      variant: 'success'
                    })
                      return res;
                    });
          
                
            
                  
                } catch (err) {
                  enqueueSnackbar(err.message, {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                  },
                    variant: 'error'
                  })
                  console.log(err.message);
                  setStatus({ success: false });
                  setErrors({ submit: err });
                  setSubmitting(false);
                }
              }
        
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Card>
          <CardHeader title="Actions" />
          <Divider />
          <>
          {loading ? ( <Box
                display="flex"
                justifyContent="center"
                my={5}
              >
                <CircularProgress />
              </Box>) : (

 <Box>
   
    {check === "OPERATION_DENIED" ? (
      <Box >
          <Box justifyContent = "center" align = "center" alignItems= 'center' display =  'flex' flexDirection = "column" minHeight = {150} minWidth = {150}>
          <SvgIcon fontSize = "large">
          <PeopleOutlineRoundedIcon />
          </SvgIcon>
       
          <Typography>
            You dont have an access to  edit Academic Year 
          </Typography>
      
          </Box>
          <Box  mr = {2} mb ={2} display = "flex">
                         <Box flexGrow = {1} />
                        
                           <ThemeProvider theme={theme}>
                           <Button
                           color="primary"
                          
                           size="large"
                               onClick = {open}
                           variant="contained"
                           className={{ disabled: classes.root1 }}
                         >
                            
                           OK
                         </Button>
                         </ThemeProvider>
                        
                         
                         
                         </Box>
                       </Box>
      ) : (
         
              <form  noValidate onSubmit={handleSubmit}>
                              <Box px={2} py={1.5} style={{ backgroundColor: '#fafafa' }}>
                              <CardContent>

                <Box
          display="flex"
          alignItems="center"
          m="1%"
        >
          <SvgIcon fontSize="large">
                <ReportProblemOutlinedIcon />
                </SvgIcon>
          <Box ml={2}>
          {id.academicyear_status === "inactive" && (
          <Typography
            
              color="textPrimary"
              
              variant="h5"
            >
            {`Are you sure you want to activate Academic Year ${id.academicyear_yearname}  `}
            </Typography>
             )}
                {id.academicyear_status === "active" && (
          <Typography
            
              color="textPrimary"
              
              variant="h5"
            >
             {`Are you sure you want to Deactivate Academic Year ${id.academicyear_yearname} `}
            </Typography>
             )}
          </Box>
        
        </Box>
        </CardContent>
        </Box> 
        <Divider />
               
        <Box px={2} py={1.5} height="auto" display = "flex">                  <Box flexGrow = {1} />
                  <Box flexGrow = {1} />
                  {id.academicyear_status === "active" && (
                     <>
                      <Button
                     size="medium"
                     color="blue"
                     onClick={open}
                     variant="outlined"
                     label={'Cancel'}
                   />
                   <Box mr={1.5} />
                   <SubmitButton
                   size="medium"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                         {isSubmitting && (<CircularProgress className = {classes.root}
                                        color = "secondary"
                                        size={20}
                                      />
                      )}
                   DEACTIVATE YEAR
                  </SubmitButton>
                  </>
                  )}
                  {id.academicyear_status === "inactive" && (
                      <>
                       <Button
             size="medium"
             color="blue"
             onClick={open}
             variant="outlined"
             label={'Cancel'}
           />
           <Box mr={1.5} />
           <SubmitButton
                   size="medium"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                     {isSubmitting && (<CircularProgress className = {classes.root}
                                        color = "secondary"
                                        size={20}
                                      />
                      )}
                      ACTIVATE YEAR
                  </SubmitButton>
                  </>
                  )}
                 
                </Box>
              </form>
             )}
           
                      </Box>
                          )}
          </>
        </Card>
        </PerfectScrollbar>
      )}
    </Formik>
  );
};

UserAction.protoTypes = {
  className: PropTypes.string,
  institutecontact : PropTypes.object.isRequired,
  instituteaddress : PropTypes.object.isRequired,
 
}
UserAction.defaultProps = {

  institutecontact: () => {},
  instituteaddress : () => {},

}

export default UserAction;
 
