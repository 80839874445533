import { createMuiTheme ,ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#33691e" 
               },
               secondary: {
                main: "#fafafa" 
                       },
      action: {
        disabledBackground: '#33691e',
        disabled: '#fafafa',
        
      }
    }
  });

  

  export default theme