import client from './GQLCompanyClient';
import gql from 'graphql-tag';

const getInstitution = async(id) => {
    const getstudents = gql`
    query get_tenant_by_id($institution_id : String!) {
      get_tenant_by_id(institution_id: $institution_id) {
        _id
        address {
          city
          country
          state
          postal_code
          street_2
          street
        }
        contact {
          fax
          mobile
          phone
          secondary_email
          website
        }
        apps{
          app_tier
          app_name
        }
        institution_id
        institution_name
        organization_name
        short_name
        
      }
    }
    `;

    return await client.query({
      query: getstudents,
      variables : {
        institution_id : id
      },
      fetchPolicy : 'network-only'
    }).then(res => {  return res.data.get_tenant_by_id})
};

export default getInstitution;