import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  SvgIcon,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Account from 'src/layouts/DashboardLayout/TopBar/Account';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Settings from 'src/layouts/DashboardLayout/TopBar/SettingsTop';
import BlueButton from 'src/components/Buttons/RoundOutlineBlue';
import Search from 'src/layouts/DashboardLayout/TopBar/Search';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from 'src/layouts/DashboardLayout/TopBar/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import Stream from 'src/layouts/DashboardLayout/TopBar/Stream';
import Year from 'src/layouts/DashboardLayout/TopBar/Year';
import Term from 'src/layouts/DashboardLayout/TopBar/Term';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import getInstitution from 'src/utils/getInstitution';
import client from 'src/utils/GQLHRMSClient';
import Loading from 'src/components/Loading'
import useStream from 'src/hooks/useStream';
import useTerm from 'src/hooks/useTerm';
import useYear from 'src/hooks/useYear';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PaymentsIcon from '@mui/icons-material/Payments';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CastForEducationOutlined from '@material-ui/icons/CastForEducationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import { ChevronLeft } from 'react-feather';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';

const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#FFFFFF'
  }
})(AppBar);

const Users = {
  // subheader: 'Admission Settings Panel',
  items: [
    {
      title: 'General Settings',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Institution',
          href: '/app/admin/settings/general',
          icon: BusinessIcon
        },
        {
          title: 'User Management',
          href: '/app/admin/settings/user_management',
          icon: SwapVerticalCircleOutlinedIcon
        },

        {
          title: 'Notification Templates',
          href: '/app/admin/settings/Notification',
          icon: DescriptionIcon
        }
      ]
    },
    {
      title: 'Academic Settings',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Stream',
          href: '/app/admin/settings/stream',
          icon: ViewStreamOutlinedIcon
        },
        {
          title: 'Batch',
          href: '/app/admin/settings/batch',
          icon: GroupWorkOutlinedIcon
        },
        {
          title: 'Academic Year',
          href: '/app/admin/settings/academicyear',
          icon: DateRangeIcon
        },
        {
          title: 'Programs',
          href: '/app/admin/settings/programs',
          icon: ClassOutlinedIcon
        },
        {
          title: 'Quota',
          href: '/app/admin/settings/quota',
          icon: CastForEducationOutlined
        }
      ]
    },
    // {
    //   title: 'Infrastructure',
    //   href: '/app/admin/settings',
    //   // icon: SettingsSuggestRoundedIcon,
    //   items: [
    //     {
    //       title: 'Room Configuration',
    //       href: '/app/admin/settings/RoomConfiguration',
    //       icon: ViewStreamOutlinedIcon
    //     },
       
    //   ]
    // }
  ]
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: `${item.href}/*`,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  red: {
    color: '#ff6f00',
    cursor: 'pointer'
  },
  root1: {
    display: 'flex',
    paddingLeft: 256
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    //top: 64,
    height: '100%'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: {
        enteringScreen: 225,
        leavingScreen: 195
      }
    })
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: '#000000'
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: `${item.href}/*`,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({
  className,
  open,
  setOpen,
  onMobileNavOpen,
  onMobileNavClose,
  ...rest
}) => {
  const classes = useStyles();

  const location = useLocation();
  const { user } = useAuth();
  const { stream } = useStream();
  const { year } = useYear();
  const { term } = useTerm();
  console.log('topbar', stream, year, term);
  const isMountedRef = useIsMountedRef();
  const [image, setImage] = useState(null);
  const [om, setOM] = useState(false);
  const handleDrawer1 = () => {
    if (om) {
      setOM(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOM(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  const theme1 = useTheme();
  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  const getEmployees = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery($user_email: String!) {
          get_employee_by_email(user_email: $user_email) {
            _id
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          variables: {
            user_email: `${user.email}`
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        getStudentImage(data.get_employee_by_email._id);
      }
      // setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  let bucketName =
    process.env.REACT_APP_ENV === 'test' ? 'erpemployees' : 'erpemployees-prod';

  var imageFolderPhotosKey = encodeURIComponent('profile_picture') + '/';

  const s3 = new AWS.S3();

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const getStudentImage = async applicant => {
    try {
      var photoKey = imageFolderPhotosKey + applicant;
      var img = await s3
        .getObject({
          Bucket: bucketName,
          Key: photoKey
        })
        .promise()
        .then(res => {
          if (res) {
            // console.log(res);
            return res;
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log(err);
        });

      if (img === undefined) {
        setImage(null);
      } else {
        console.log(img);
        var base64Flag = `data:${img.ContentType};base64,`;

        var imgConvD = _arrayBufferToBase64(img.Body);
        setImage(base64Flag + imgConvD);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  window.addEventListener('storage', function(e) {
    // console.log('storage fired');
    // this.fetchData();
  });
  // console.log(window.dispatchEvent(new Event('storage')));

  // window.addEventListener('storage', console.log);

  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));

  const [loadingInstut, setLoadingInstut] = useState(true);
  const [instutionDetail, setInstutionDetail] = useState(null);
  let folderName = user['custom:institution_id'];
  let promise = getInstitution(folderName);
  const consumer = () => {
    promise.then(
      result => {
        // instution=result.data.get_tenant_by_id

        setInstutionDetail(
          result.apps.find(
            x => x.app_name == 'human resource management service'
          )
        );
        setLoadingInstut(false);
      },
      error => {
        //console.log('We have encountered an Error!', error);
      }
    );
  };
  consumer();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box mb={1} mt={1} ml={1.5} display="flex">
            <SchoolRoundedIcon className={classes.red} />
            <Box mr={1} />
            <Box mt={0.3}>
              <Typography variant="h5" color="textPrimary">
              Academics
              </Typography>
            </Box>
          </Box>
          <Box px={2} pt={2} mb={2}>
            <BlueButton
              variant="outlined"
              color="secondary"
              size="medium"
              fullWidth
              component={RouterLink}
              to={{
                pathname: `/app/admin/home`
              }}
              startIcon={
                <SvgIcon fontSize="small">
                  <ChevronLeft strokeWidth="2px" size="24px" />
                </SvgIcon>
              }
            >
              Back to Main Menu
            </BlueButton>
          </Box>

          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>

        <Divider />
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );

  const Rightcontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderRightNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />

        <Box p={2}></Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return (
    <div className={classes.root}>
      {(stream == null || year == null || term == null) && (
        <Loading open={true} />
      )}
      {mobileDevice ? (
        <>
          <CssBaseline />

          <ColorAppBar className={om ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>
              {/* {om === true && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer1}
                  edge="start"
                  className={clsx(classes.menuButton, om)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              )}
              {om === false && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer1}
                  edge="start"
                  className={clsx(classes.menuButton, om)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )} */}

              {/* <Box mr={1} /> */}
              <Stream />

              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.mobileDrawer }}
            variant="temporary"
            anchor="left"
            open={om}
            onClose={handleDrawer1}
          >
            {content}
          </Drawer>
        </>
      ) : (
        <>
          <CssBaseline />

          <ColorAppBar
            className={open ? classes.root1 : classes.root}
            {...rest}
          >
            <Toolbar className={classes.toolbar}>
              {open === true && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              )}
              {open === false && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}

              {/* <Box mr={1} /> */}
              <Stream />
              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Search />

              <Notifications />
              {(user.user_group === 'institution_head'||
            user.user_group === 'institution_coordinator' )&& <Settings />}
              {user.user_group === 'owner' && <Settings />}
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.desktopDrawer }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {content}
          </Drawer>
        </>
      )}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
