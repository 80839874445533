import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  SvgIcon,
  Typography,
  TextField,
  withStyles,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import wait from 'src/utils/wait';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import gql from 'graphql-tag';
import client from 'src/utils/GQLSettingsClient';
import StreamYears from '../Batch/AddBatch/streamYears';
import theme from 'src/utils/ButtonTheme';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import SubmitButton from 'src/components/Button/RoundBlue';
import Button from 'src/components/Button/CustomButton';

const GreenButton = withStyles({
  root: {
    backgroundColor: '#61B38A',
    color: 'white',
    '&:hover': {
      backgroundColor: '#61B38A'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 10
  }
}));

const AddAccreditation = ({
  getStreams,
  handleSubmit,
  open,
  streams,
  id,
  submitting,
  accreditationinfo
}) => {
  const [isAlertVisible, setAlertVisible] = useState(true);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState();
  const getCustomers = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          check_for_academic {
            message
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        setCheck(data.check_for_academic.message);
        setLoading(false);
        console.log(data.check_for_academic.message);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <Formik
      initialValues={{
        start_year: '',

        submit: null
      }}
      validationSchema={Yup.object().shape({
        start_year: Yup.string().required('Required')
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          await wait(1000);
          console.log(
            `${moment(+values.start_year + +1).format('MM/DD/YYYY')}`
          );
          console.log(`${+values.start_year + +1}`);
          let end_year = `${+values.start_year + +1}`;
          try {
            const ADD = gql`
              mutation add_academic_year(
                $start_date: String!
                $end_date: String!
                $year_name: String!
                $status: String!
              ) {
                add_academic_year(
                  start_date: $start_date
                  end_date: $end_date
                  year_name: $year_name
                  status: $status
                ) {
                  _id
                }
              }
            `;

            const { data } = await client
              .mutate({
                mutation: ADD,

                variables: {
                  start_date: `${moment(values.start_year).format(
                    'MM/DD/YYYY'
                  )}`,
                  end_date: `${moment(end_year).format('MM/DD/YYYY')}`,
                  year_name: `${values.start_year} - ${+values.start_year +
                    +1}`,
                  status: 'active'
                }
              })
              .then(res => {
                console.log(res);
                open();
                enqueueSnackbar('Academic Year Added', {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  },
                  variant: 'success'
                });
                window.location.reload(); // This will refresh the entire page

                return res;
              });

            console.log(data.add_accrediation);
          } catch (err) {
            enqueueSnackbar(err.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'error'
            });
            console.log(err.message);
            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Card>
            <CardHeader title={`Add Academic Year`} />
            <Divider />

            {loading ? (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {check === 'OPERATION_DENIED' ? (
                  <Box>
                    <Box
                      justifyContent="center"
                      align="center"
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      minHeight={150}
                      minWidth={150}
                    >
                      <SvgIcon fontSize="large">
                        <PeopleOutlineRoundedIcon />
                      </SvgIcon>

                      <Typography>
                        You dont have an access to add Academic Year
                      </Typography>
                    </Box>
                    <Box mr={2} mb={2} display="flex">
                      <Box flexGrow={1} />

                      <ThemeProvider theme={theme}>
                        <Button
                          color="primary"
                          size="large"
                          onClick={open}
                          variant="contained"
                          className={{ disabled: classes.root1 }}
                        >
                          OK
                        </Button>
                      </ThemeProvider>
                    </Box>
                  </Box>
                ) : (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box
                      px={2}
                      pt={1}
                      mb={-3}
                      style={{ backgroundColor: '#fafafa' }}
                    >
                      <CardContent>
                        <Grid container spaceing={5}>
                          <Grid item xs={3} md={3} lg={3}>
                            <Box mt={0.5}>
                              <Typography variant="body2" color="textSecondary">
                                Start Year
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={9} md={9} lg={9}>
                            <Box
                              mr={0.5}
                              display={'flex'}
                              alignItems={'center'}
                              width="100%"
                            >
                              <TextField
                                error={Boolean(
                                  touched.start_year && errors.start_year
                                )}
                                helperText={
                                  touched.start_year && errors.start_year
                                }
                                //label="Start Year"
                                name="start_year"
                                size="small"
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={values.start_year}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                              >
                                <option label="" />
                                {StreamYears.map(statusOption => (
                                  <option
                                    key={statusOption.value}
                                    value={statusOption.value}
                                  >
                                    {statusOption.value}
                                  </option>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box mt={2} />
                        <Grid container spaceing={5}>
                          <Grid item xs={3} md={3} lg={3}>
                            <Box mt={0.5}>
                              <Typography variant="body2" color="textSecondary">
                                End Year
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={9} md={9} lg={9}>
                            <Box
                              mr={0.5}
                              display={'flex'}
                              alignItems={'center'}
                              width="100%"
                            >
                              {
                              // values.start_year && 
                              (
                                <TextField
                                  error={Boolean(
                                    touched.end_year && errors.end_year
                                  )}
                                  helperText={
                                    touched.end_year && errors.end_year
                                  }
                                  fullWidth
                                  size="small"
                                  //label="End Year"
                                  name="end_year"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={`${values.start_year&&+values.start_year + +1}`}
                                  variant="outlined"
                                  disabled
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        <Box mt={2} />
                        <Grid container spaceing={5}>
                          <Grid item xs={3} md={3} lg={3}>
                            <Box mt={0.5}>
                              <Typography variant="body2" color="textSecondary">
                                Academic Year
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={9} md={9} lg={9}>
                            <Box
                              mr={0.5}
                              display={'flex'}
                              alignItems={'center'}
                              width="100%"
                            >
                              {
                              // values.start_year &&
                               (
                                <TextField
                                  error={Boolean(
                                    touched.end_year && errors.end_year
                                  )}
                                  helperText={
                                    touched.end_year && errors.end_year
                                  }
                                  fullWidth
                                  size="small"
                                  //label="End Year"
                                  name="end_year"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={`${values.start_year&&
                                    values.start_year
                                  } ${values.start_year&&`-`} ${values.start_year&&+values.start_year + +1}`}
                                  variant="outlined"
                                  disabled
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                        <Box mt={2} />
                      </CardContent>
                    </Box>

                    <Divider />
                    <Box px={2} py={1.5} height="auto" display="flex">
                      <Box flexGrow={1} />
                      <>
                        <Button
                          size="medium"
                          color="blue"
                          onClick={open}
                          variant="outlined"
                          label={'Cancel'}
                        />
                        <Box mr={1.5} />
                        <SubmitButton
                          size="medium"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting && (
                            <CircularProgress
                              className={classes.root}
                              color="secondary"
                              size={20}
                            />
                          )}
                          ADD
                        </SubmitButton>
                      </>
                    </Box>
                  </form>
                )}
              </Box>
            )}
          </Card>
        </PerfectScrollbar>
      )}
    </Formik>
  );
};

AddAccreditation.protoTypes = {
  className: PropTypes.string,
  institutecontact: PropTypes.object.isRequired,
  instituteaddress: PropTypes.object.isRequired,
  open: PropTypes.func
};
AddAccreditation.defaultProps = {
  open: () => {},
  institutecontact: () => {},
  instituteaddress: () => {}
};

export default AddAccreditation;
