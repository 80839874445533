
import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Rules from 'src/Rules.json'

const CustomButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: Rules.nav_top_bar.navbar_background_color,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Rules.nav_top_bar.navbar_background_color
    },
  }
}))(Button);

export default CustomButton;
