import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {
  Box,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Account from './Account';
import Settings from './SettingsTop';
import Search from './Search';
import NavItem from './NavItem';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import Stream from './Stream';
import Year from './Year';
import Term from './Term';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import getInstitution from 'src/utils/getInstitution';
import RememberMeOutlinedIcon from '@mui/icons-material/RememberMeOutlined';
import client from 'src/utils/GQLHRMSClient';
import Loading from 'src/components/Loading'
import useStream from 'src/hooks/useStream';
import useTerm from 'src/hooks/useTerm';
import useYear from 'src/hooks/useYear';
import { useDashboardContext } from 'src/contexts/DashboardContext';
import Rules from 'src/Rules.json';
import NavIconItem from './NavIconItem';
import fetchDocument from 'src/components/get_document_image';

const ColorAppBar = withStyles({
  root: {
    backgroundColor: Rules.nav_top_bar.textColor
  }
})(AppBar);

const Users = {
  items: [
    {
      title: 'Home',
      href: '/app/admin/home',
      icon: 'material-symbols:home-outline'
    },
    {
      title: 'Classes',
      href: '/app/admin/academics',
      icon: 'mdi:college'
    },
    {
      title: 'Attendance',
      href: '/app/admin/attendanceNew',
      icon: 'fluent:checkbox-person-16-regular'
    },
    {
      title: 'Organizer',
      href: '/app/admin/organizer',
      icon: 'bi:card-text'
    },
   
    {
      title: 'Class Incharge',
      href: '/app/ClassIncharge',
      icon: 'ph:chalkboard-teacher'
    },
    {
      title: 'Mentoring',
      href: '/app/Mentor/Academic/MentorView/Mentor/Academic',
      icon: 'simple-icons:codementor'
    },
    {
      title: 'Counselling',
      href: '/app/Counselling/Academic/CounsellingView/Counselling/Academic',
      icon: 'guidance:meeting-point'
    }
    
   
  ]
};

const UsersOthers = {
  items: [

   
    // {
    //   title: 'Calender',
    //   href: '/app/admin/calender',
    //   icon: 'clarity:calendar-line'
    // },
    // {
    //   title: 'My Tasks',
    //   href: '/app/MyTasks/Academic/MyTasksView/MyTasks/Academic',
    //   icon: 'cil:task'
    // },
    {
      title: 'Events',
      href: '/app/Event/Academic/EventView/Event/Academic/Event',
      icon: 'bx:calendar'
    },
    {
      title: 'Meetings',
      href: '/app/Meeting/Academic/MeetingView/Event/Academic/Meeting',
      icon: 'guidance:meeting-room'
    },
    {
      title: 'Feedbacks',
      href: '/app/Feedback/Academic/FeedbackView/Feedback/Academic',
      icon: 'material-symbols:feedback-outline'
      

      },
    {
      title: 'Tasks',
      href: '/app/Task/Academic/TaskView/Task/Academic',
      icon: 'ph:chalkboard-teacher'
    },
    {
      title: 'Clubs',
      href: '/app/Groups',
      icon: 'fa:object-group'
    },
    {
      title: 'Resources',
      href: '/app/Resources/Academic/ResourcesView/Blueprint/Academic/Resource',
      icon: 'carbon:group-resource'
    },
    {
      title: 'Circulars',
      href: '/app/Circulars/Academic/CircularsView/Blueprint/Academic/Circular',
      icon: 'ic:sharp-blur-circular'
    },
      {
        title: 'Reports',
        href: '/app/admin/AllReports',
        icon: 'iconoir:reports'
      },
      // {
      //   title: 'Exam Seating',
      //   href: '/app/onlineExam',
      //   icon: 'material-symbols-light:event-seat-outline-rounded',
      //   items: [
      //     {
      //       title: 'Online Exam',
      //       href: '/app/onlineExam/online',
      //       icon: 'material-symbols-light:online-prediction-rounded'
      //     },
      //     {
      //       title: 'Online Test',
      //       href: '/app/exam/onlineExam',
      //       icon: 'arcticons:readera'
      //     },
      //     {
      //       title: 'Offline Exam',
      //       href: '/app/onlineExam/offline',
      //       icon: 'ph:exam-light'
      //     },
      //     {
      //       title: 'Reports',
      //       href: '/app/examfee/reports',
      //       icon: 'iconoir:reports'
      //     }
      //   ]
      // },
     
    //   {
    //   title: 'Documents',
    //   href: '/app/Document/Academic/DocumentView/Document/Academic',
    //   icon: 'fe:document'
    // },
  ]
};

const Users2 = {
  items: [
    {
      title: 'Organizer',
      href: '/app/admin/academics/tasks',
      icon: 'bi:card-text',
      items: [
        // {
        //   title: 'Profile',
        //   href: '/app/admin/profile',
        //   icon: AccountBoxOutlinedIcon
        // },
        {
          title: 'Calender',
          href: '/app/admin/calender',
          icon: 'clarity:calendar-line'
        },
        // {
        //   title: 'ID Card',
        //   href: '/app/admin/idcard',
        //   icon: 'la:id-card'
        // },
        {
          title: 'Biometrics',
          href: '/app/admin/facultyAttendance',
          icon: 'material-symbols:fingerprint'
        },
        {
          title: 'Movements',
          href: '/app/admin/movementregister',
          icon: 'mdi:transit-transfer'
        },
        {
          title: 'Leave Tracker',
          href: '/app/admin/leaves',
          icon: 'pepicons-pop:leave'
        },
        {
          title: 'Payroll',
          href: '/app/admin/payroll',
          icon: 'streamline:payment-10'
        },

       
      ]
    }
  ]
};
const Users2Desktop = {
  items: [
    // {
    //   title: 'Organizer',
    //   href: '/app/admin/academics/tasks',
    //   icon: 'bi:card-text',
    //   items: [
        // {
        //   title: 'Profile',
        //   href: '/app/admin/profile',
        //   icon: AccountBoxOutlinedIcon
        // },
  
        // {
        //   title: 'ID Card',
        //   href: '/app/admin/idcard',
        //   icon: 'la:id-card'
        // },
        //  {
        //    title: 'Organizer',
        //    href: '/app/admin/organizer',
        //    icon: 'bi:card-text'
        //  },
        // {
        //   title: 'Movements',
        //   href: '/app/admin/movementregister',
        //   icon: 'mdi:transit-transfer'
        // },
        // {
        //   title: 'Leave Tracker',
        //   href: '/app/admin/leaves',
        //   icon: 'pepicons-pop:leave'
        // },
        // {
        //   title: 'Payroll',
        //   href: '/app/admin/payroll',
        //   icon: 'streamline:payment-10'
        // },

       
     //]
    //}
  ]
};
const Users22 = {
  items: [
    {
      title: 'Organizer',
      href: '/app/admin/academics/tasks',
      icon: 'bi:card-text',
      items: [
       
        {
          title: 'Calender',
          href: '/app/admin/calender',
          icon: 'clarity:calendar-line'
        },

        // {
        //   title: 'Biometrics',
        //   href: '/app/admin/facultyAttendance',
        //   icon: FingerprintIcon
        // },
        // {
        //   title: 'Movements',
        //   href: '/app/admin/movementregister',
        //   icon: TransferWithinAStationIcon
        // },
        // {
        //   title: 'Leaves',
        //   href: '/app/admin/leaves',
        //   icon: ClearAllIcon
        // },
        {
          title: 'Payroll',
          href: '/app/admin/payroll',
          icon: 'streamline:payment-10'
        },

      
      ]
    }
  ]
};

const Events = {
  items: [
    {
      title: 'Registrations',
      href: '/app/admin/events',
      icon: 'material-symbols-light:event-note-sharp',
      items: [
        {
          title: 'Events',
          href: '/app/admin/events'
          
        },
        {
          title: 'Students',
          href: '/app/admin/eventStudents'
          
        }
      ]
    }
  ]
};

const davana = {
  items: [
    {
      title: 'Davana',
      href: '/app/admin/events/davana',
      icon: 'material-symbols-light:event-note-sharp'
    }
  ]
};

const admin_actions = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/head/att_dashboard',
      icon: 'mdi-light:view-dashboard'
      
    },
   
    {
      title: 'Students',
      href: '/app/head/allStudents',
      icon: 'pepicons-pencil:people'
      // items: [
      //   {
      //     title: 'Student View',
      //     href: '/app/head/allStudents',
      //     icon: AssignmentTurnedInOutlinedIcon
      //   }
      // ]
    },

    {
      title: 'Faculties',
      href: '/app/head/cal/faculty_view',
      icon: 'fluent:people-list-32-regular'
      // items: [
      //   {
      //     title: 'Faculty View',
      //     href: '/app/head/faculties',
      //     icon: AssignmentTurnedInOutlinedIcon
      //   }
      // ]
    },
    // {
    //   title: 'Events',
    //   href: '/app/admin/events',
    //   icon: 'bx:calendar'
    // },
    {
      title: 'Class Setup',
      href: '/app/head/academicSetup',
      icon: 'mingcute:settings-7-line'
    },
    
    
    // {
    //   title: 'Academic Calender',
    //   href: '/app/head/cal/academic_calendar',
    //   icon: 'bx:calendar'
    // },
    {
      title: 'OBE',
      href: '/app/head/obe',
      icon: 'fluent-mdl2:assessment-group',
      items: [
        {
          title: 'Mapping',
          href: '/app/head/obe/mapping'
        },
        {
          title: 'Reports',
          href: '/app/head/obe/reports'
        },
        {
          title: 'Setup',
          href: '/app/head/obe/setup'
        }
      ]
    },
    {
      title: 'Admin Reports',
      href: '/app/admin/AdminReports',
      icon: 'mdi:report-bell-curve-cumulative'
    },
   
    
    {  
      title: 'Progress Cards',
      href: '/app/admin/progresscards',
      icon: 'material-symbols-light:id-card-outline',
    },
    // {  
    //   title: 'Notification Logs',
    //   href: '/app/admin/notifications',
    //   icon: 'zondicons:notifications-outline',
    // },
    // {
    //   title: 'Course',
    //   href: '/app/Course/Academic/CourseView/Course/Academic',
    //   icon: 'ic:outline-book'
      

    //   },
    //   {
    //     title: 'Configuration',
    //     href: '/app/Configuration/Academic/ConfigurationView/Configuration/Academic',
    //     icon: 'uil:setting'
        
  
    //     },
    //     {
    //       title: 'Course Bank',
    //       href: '/app/admin/course_bank',
    //       icon: 'mdi-light:view-dashboard'
    //     },
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const course_rules_actions = {
  items: [
   
    // {
    //   title: 'Course',
    //   href: '/app/Course/Academic/CourseView/Course/Academic',
    //   icon: 'ic:outline-book'
      

    //   },
    {
      title: 'Course Register',
      href: '/app/admin/course_rules',
      icon: 'carbon:deploy-rules'
    },
            
              // {
              //   title: 'Classes',
              //   href: '/app/Classes/Academic/ClassesView/Classes/Academic',
              //   icon: 'carbon:batch-job'
                
          
              //   },
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const owner_actions = {
  items: [
   
    // {
    //   title: 'Course',
    //   href: '/app/Course/Academic/CourseView/Course/Academic',
    //   icon: 'ic:outline-book'
      

    //   },
      {
        title: 'Configuration',
        href: '/app/Configuration/Academic/ConfigurationView/Configuration/Academic',
        icon: 'uil:setting'
        
  
        },
        {
          title: 'Course Bank',
          href: '/app/admin/course_bank',
          icon: 'mdi-light:view-dashboard'
        },
       
        // {
        //   title: 'New Faculties',
        //   href: '/app/People/Academic/FacultyView/People/Academic/Faculty',
        //   icon: 'fluent:people-list-32-regular'
          
    
        //   },
        //   {
        //     title: 'New Students',
        //     href: '/app/People/Academic/StudentView/People/Academic/Student',
        //     icon: 'pepicons-pencil:people'
            
      
        //     },
            {
              title: 'Batch',
              href: '/app/Batch/Academic/BatchView/Batch/Academic',
              icon: 'carbon:batch-job'
              
        
              },
            
              // {
              //   title: 'Classes',
              //   href: '/app/Classes/Academic/ClassesView/Classes/Academic',
              //   icon: 'carbon:batch-job'
                
          
              //   },
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const exam_actions = {
  items: [
        {
          title: 'Va-Mo Map',
          href: '/app/People/Academic/VaMOMapView/People/Academic/Va-MoMap',
          icon: 'fluent:people-list-32-regular'
          
    
          },
          {
            title: 'Packet Mapping',
            href: '/app/People/Academic/PacketMappingView/People/Academic/Packets',
            icon: 'ri:newspaper-line'
            
      
            },
            {
              title: 'Approvals',
              href: '/app/People/Academic/ApprovalView/People/Academic/Approvals',
              icon: 'ic:baseline-transform'
              
        
              },
              {
                title: 'Valuators',
                href: '/app/People/Academic/ValuatorView/People/Academic/Valuators',
                icon: 'pepicons-pencil:people'
                
          
                },
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const exam_seating_actions = {
  items: [
    {
      title: 'Online Exam',
      href: '/app/onlineExam/online',
      icon: 'material-symbols-light:online-prediction-rounded'
    },
    {
      title: 'Online Test',
      href: '/app/exam/onlineExam',
      icon: 'arcticons:readera'
    },
    {
      title: 'Offline Exam',
      href: '/app/onlineExam/offline',
      icon: 'ph:exam-light'
    },
    {
      title: 'Reports',
      href: '/app/examfee/reports',
      icon: 'iconoir:reports'
    }
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const hr_tracker = {
  items: [
    {
      title: 'Employees',
      href: '/app/hr/cal/faculty_view',
      icon: 'fluent:people-list-32-regular'
      // items: [
      //   {
      //     title: 'Faculty View',
      //     href: '/app/head/faculties',
      //     icon: AssignmentTurnedInOutlinedIcon
      //   }
      // ]
    },
    {
      title: 'Attendance',
      href: '/app/payroll/attendancenew',
      icon: 'fluent:checkbox-person-16-regular'
    },
    {
      title: 'Timesheet',
      href: '/app/payroll/attendance',
      icon: 'icon-park-outline:reverse-operation-out'
    },
    {
      title: 'Leaves',
      href: '/app/admin/leaveApplications/hr_leave',
      icon: 'material-symbols:clear-all'
    },
    {
      title: 'Movements',
      href: '/app/admin/movement_register/hr_movement',
      icon: 'mdi:transfer-within-a-station'
    },
    {
      title: 'Approvals',
      href: '/app/admin/Employees/approvals',
      icon: 'ic:baseline-transform'
    },
    {
      title: 'Reports',
      href: '/app/admin/HRReports',
      icon: 'iconoir:reports'
    },
    // {
    //   title: 'Actions',
    //   href: '/app/coordinator/actions',
    //   icon: 'ic:baseline-pending-actions'
    // },
    // {
    //   title: 'Schedules',
    //   href: '/app/head/schedules',
    //   icon: 'grommet-icons:schedules'
    // },
    // {
    //   title: 'OBE',
    //   href: '/app/head/obe',
    //   icon: AssessmentOutlinedIcon
    // },
   
    // {
    //   title: 'OBE Reports',
    //   href: '/app/head/obe/reports',
    //   icon: AssessmentOutlinedIcon
    // },
    // {
    //   title: 'OBE Setup',
    //   href: '/app/head/obe/setup',
    //   icon: SettingsSuggestOutlinedIcon
    // },


    // {
    //   title: 'Result Entry',
    //   href: '/app/head/ResultEntry',
    //   icon: 'carbon:result'
    // },

  ]
};
const course_bank = {
  items: [
    // {
    //   title: 'Course Bank',
    //   href: '/app/admin/course_bank',
    //   icon: 'mdi-light:view-dashboard'
    // },
    // {
    //   title: 'Course Category',
    //   href: '/app/admin/course_category',
    //   icon: 'mdi-light:view-dashboard'
    // },
    // {
    //   title: 'Credits',
    //   href: '/app/admin/credits',
    //   icon: 'mdi-light:view-dashboard'
    // },
    // {
    //   title: 'Aggrigation',
    //   href: '/app/admin/aggrigation',
    //   icon: 'mdi-light:view-dashboard'
    // },
    // {
    //   title: 'Universities',
    //   href: '/app/admin/university',
    //   icon: 'mdi-light:view-dashboard'
    // }
  ]
};


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    //paddingLeft: 90
  },
  root1: {
    display: 'flex',
    paddingLeft: 256
  },
  mobileDrawer: {
    width: 90,
    //top: 64,
    backgroundColor: Rules.nav_top_bar.navbar_background_color,
    color: Rules.nav_top_bar.textColor,
    height: '100%'
  },
  desktopDrawer: {
    width: 256,
    //top: 64,
    backgroundColor: Rules.nav_top_bar.navbar_background_color,
    color: Rules.nav_top_bar.textColor,
    height: '100%'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: {
        enteringScreen: 225,
        leavingScreen: 195
      }
    })
  },
  menuButtonlight: {
    marginRight: theme.spacing(2),
    color: Rules.nav_top_bar.topbar_background_color
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: Rules.nav_top_bar.topbar_menu_button_color
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({
  className,
 
  onMobileNavOpen,
  onMobileNavClose,
  ...rest
}) => {
  const classes = useStyles();
  const { open, setOpen } = useDashboardContext();
  const location = useLocation();
  const { user } = useAuth();
  const { stream } = useStream();
  const { year } = useYear();
  const { term } = useTerm();
  const isMountedRef = useIsMountedRef();
  const [image, setImage] = useState(null);

  const theme1 = useTheme();
  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  const getEmployees = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery($user_email: String!) {
          get_employee_by_email(user_email: $user_email) {
            _id
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          variables: {
            user_email: `${user.email}`
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        getStudentImage(data.get_employee_by_email._id);
      }
      // setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  let bucketName =
    process.env.REACT_APP_ENV === 'test' ? 'erpemployees' : 'erpemployees-prod';

  var imageFolderPhotosKey = encodeURIComponent('profile_picture') + '/';

  const s3 = new AWS.S3();

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const getStudentImage = async applicant => {
    try {
      var photoKey = imageFolderPhotosKey + applicant;
      const i = await fetchDocument('employee_profile', photoKey);
      setImage(i);
    } catch (err) {
      console.error(err);
    }
  };

  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <>
      {open ? (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
      ) : (
        <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildIconRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>  
      )}
      </>
    );
  }
  
  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;
  
    if (item.items) {
      const open = matchPath(pathname, {
        path: `${item.href}/*`,
        exact: false
      });
  
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          handleDrawer={handleDrawer}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          handleDrawer={handleDrawer}
        />
      );
    }
  
    return acc;
  }

  function reduceChildIconRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;
  
    if (item.items) {
      const open = matchPath(pathname, {
        path: `${item.href}/*`,
        exact: false
      });
  
      acc.push(
        <NavIconItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          handleDrawer={handleDrawer}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavIconItem>
      );
    } else {
      acc.push(
        <NavIconItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          handleDrawer={handleDrawer}
        />
      );
    }
  
    return acc;
  }
  

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };
  console.log("opennnnnn" , open)
  window.addEventListener('storage', function(e) {
    // console.log('storage fired');
    // this.fetchData();
  });
  // console.log(window.dispatchEvent(new Event('storage')));

  // window.addEventListener('storage', console.log);

  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));

  const [loadingInstut, setLoadingInstut] = useState(true);
  const [instutionDetail, setInstutionDetail] = useState(null);
  let folderName = user['custom:institution_id'];
  let promise = getInstitution(folderName);
  const consumer = () => {
    promise.then(
      result => {
        setInstutionDetail(
          result.apps.find(
            x => x.app_name == 'human resource management service'
          )
        );
        setLoadingInstut(false);
      },
      error => {
        //console.log('We have encountered an Error!', error);
      }
    );
  };
  consumer();

  // console.log('instutionDetail',instutionDetail.app_tier)
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      {open === true && (
      <Box style={{position:'sticky',top:0,backgroundColor:'#002250',zIndex:1000}}>
        <Box pt={2.5} pb={1.4} px={1.5} style={{backgroundColor:'#002250'}}>
          <Box mt={-1} ml={1.2} display="flex" style={{backgroundColor:'#002250'}}>
         
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButtonlight, open)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            
        
            <Box mr={0} />
            <Box mt={1}>
              {open && (
              <Typography
                variant="h5"
                style={{ color: Rules.nav_top_bar.textColor, fontSize: '20px' }}
              >
                Academics
              </Typography>
              )}
              </Box>
              </Box>
        </Box>
        <Box pb = {1}><Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_divider_color }} /></Box>

      </Box>
          )}
        {open === false && (
              <Box display='flex'  pt={1.5} pb={1.4} px={2} justifyContent='center' alignItems='center'>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButtonlight, open)}
              >
                <MenuOpenIcon fontSize="large" />
              </IconButton>
              </Box>
            )}
        <Box p={2}>
            <Box mt={-1} />
            {user.user_group === 'owner' && (
              <List
                key={course_bank.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {course_bank.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: course_bank.items,
                  pathname: location.pathname
                })}
              </List>
            )}
            <Box mt={-1} />
            <List
              key={Users.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Users.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Users.items,
                pathname: location.pathname
              })}
            </List>

            <Box mt={-1} />
            {instutionDetail && (
              <>
                {instutionDetail.app_tier == 'tier-1' ? (
                  
                  <List
                    key={mobileDevice ? Users2.subheader : Users2Desktop.subheader}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {mobileDevice ? Users2.subheader : Users2Desktop.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: mobileDevice ? Users2.items : Users2Desktop.items,
                      pathname: location.pathname
                    })}
                  </List>
                ) : (
                  <List
                    key={Users22.subheader}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {Users22.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: Users22.items,
                      pathname: location.pathname
                    })}
                  </List>
                )}
              </>
            )}

<Box mt={-1} />
            <List
              key={UsersOthers.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {UsersOthers.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: UsersOthers.items,
                pathname: location.pathname
              })}
            </List>
            <Box mt={-1} />
             {/* <Box mt={-1} />
             {(user.user_group === 'program_head'||user.user_group === 'program_coordinator') &&
              user['custom:institution_id'] == 'inst_1635843874737' && (
                <List
                  key={Events.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {Events.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: Events.items,
                    pathname: location.pathname
                  })}
                </List>
              )}  */}

           
            <Box mt={-1} />
             {user.email === 'poornimateju@gmail.com' && (
              <List
                key={davana.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {davana.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: davana.items,
                  pathname: location.pathname
                })}
              </List>
            )} 
            <Box mt={-1} />

            </Box>
            <Box>

          {(user.user_group === 'owner' ||
            user.user_group === 'institution_head' ||
            user.user_group === 'institution_coordinator' ||
            user.user_group === 'stream_head' ||
            user.user_group === 'program_head'||user.user_group === 'program_coordinator') && (
            <>
              <Box pb = {1} mt = {-1}><Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_divider_color }} /></Box>
              <Box   pt={0} p={2} >
              {open && (
                <Box mb={1} mt={1} ml={1.5} display="flex">
                  <Box mt={0.3}>
                    <Typography variant="body2" color={Rules.nav_top_bar.textColor} style={{fontWeight:500}}>
                      Admin Actions
                    </Typography>
                  </Box>
                </Box>
              )}
                <List
                  key={admin_actions.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {admin_actions.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: admin_actions.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
           {(user.user_group === 'owner'||user.user_group === 'institution_head'||user.user_group === 'program_head'||user.user_group === 'program_coordinator' ) && (
            <>
              <Box pt={-1} pr={2} pl={2} pb={2} mt={-3} >
             
                <List
                  key={course_rules_actions.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {course_rules_actions.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: course_rules_actions.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
           {(user.user_group === 'owner' ) && (
            <>
              <Box pt={-1} pr={2} pl={2} pb={2} mt={-3} >
             
                <List
                  key={owner_actions.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {owner_actions.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: owner_actions.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
           {(user.user_group === 'owner'||user.user_group === 'institution_head'||
            user.user_group === 'institution_coordinator'||user.user_group === 'program_head') && (
            <>
              <Box pb = {1} mt = {-1}><Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_divider_color }} /></Box>
              <Box   pt={0} p={2} >
              {open && (
                <Box mb={1} mt={1} ml={1.5} display="flex">
                  <Box mt={0.3}>
                    <Typography variant="body2" color={Rules.nav_top_bar.textColor} style={{fontWeight:500}}>
                      HR Tracker
                    </Typography>
                  </Box>
                </Box>
              )}
                <List
                  key={hr_tracker.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {hr_tracker.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: hr_tracker.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
          {(user.user_group === 'owner'||user.user_group === 'institution_head'||user.user_group === 'institution_coordinator'||user.user_group === 'program_head'||user.user_group === 'program_coordinator'||user.user_group === 'stream_head') && (
            <>
              <Box pb = {1} mt = {-1}><Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_divider_color }} /></Box>
              <Box   pt={0} p={2} >
              {open && (
                <Box mb={1} mt={1} ml={1.5} display="flex">
                  <Box mt={0.3}>
                    <Typography variant="body2" color={Rules.nav_top_bar.textColor} style={{fontWeight:500}}>
                      Exam Seating
                    </Typography>
                  </Box>
                </Box>
              )}
                <List
                  key={exam_seating_actions.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {exam_seating_actions.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: exam_seating_actions.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
           {(user.user_group === 'owner') && (
            <>
              <Box pb = {1} mt = {-1}><Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_divider_color }} /></Box>
              <Box   pt={0} p={2} >
              {open && (
                <Box mb={1} mt={1} ml={1.5} display="flex">
                  <Box mt={0.3}>
                    <Typography variant="body2" color={Rules.nav_top_bar.textColor} style={{fontWeight:500}}>
                      Exam
                    </Typography>
                  </Box>
                </Box>
              )}
                <List
                  key={exam_actions.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {exam_actions.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: exam_actions.items,
                    pathname: location.pathname
                  })}
                </List>
              </Box>
            </>
          )}
        </Box>

        <Divider />
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );


  return (
    <div className={classes.root}>
      {(stream == null || year == null || term == null) && (
        <Loading open={true} />
      )}
      {mobileDevice ? (
        <>
          {/* <CssBaseline />

          <ColorAppBar className={om ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>            
              <Stream />
              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.mobileDrawer }}
            variant="temporary"
            anchor="left"
            open={om}
            onClose={handleDrawer1}
          >
            {content}
          </Drawer> */}
        </>
      ) : (
        <>
          <CssBaseline />

          <ColorAppBar
            className={open ? classes.root1 : classes.root}
            {...rest}
          >
            <Toolbar className={classes.toolbar}>
              {open === false && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              )}
             
            
             
              <Stream />
              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Search />

              <Notifications />
              {(user.user_group === 'institution_head'||
            user.user_group === 'institution_coordinator') && <Settings />}
              {user.user_group === 'owner' && <Settings />}
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          {open &&
        <Drawer
        classes={{ paper:  classes.desktopDrawer }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {content}
      </Drawer> 
    //   :
    //   <Drawer
    //   classes={{ paper: classes.mobileDrawer}}
    //   variant="persistent"
    //   anchor="left"
    //   open={true}
    // >
    //   {content}
    // </Drawer> 
        }
          
        </>
      )}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
