import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLAdmissionClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const FeeSetupContext = createContext({
  ...initialCreateState,
  addprogram: () => Promise.resolve(),
  addprogram1: () => Promise.resolve(),
  addTotalmarks: () => Promise.resolve(),
  addmarks: () => Promise.resolve(),
  addmarks1: () => Promise.resolve(),
  addassignment: () => Promise.resolve(),
  addsee: () => Promise.resolve(),
  addattendance: () => Promise.resolve(),
  addlabIAmarks: () => Promise.resolve(),
  addactivity: () => Promise.resolve(),
  addLessonPlan: () => Promise.resolve(),
  addCES : () => Promise.resolve(),
});

export const FeeSetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const addprogram = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_attendance',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_attendance',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
    }
  };

  const addprogram1 = async data => {
    
    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/upload_ia_attendance',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/upload_ia_attendance',
          data
        );
      }
      dispatch({
        type: 'ADD_IMPORT',
        payload: {
          user1: response.data,
          updatesuccessfull1: true
        }
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: 'ADD_IMPORT_ERROR',
          payload: {
            err1: err.response.data,
            updatesuccessfull1: false
          }
        });
      }
    }
  };

  const addmarks = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_marks',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_marks',
          data
        );
      }
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
    }
  };

  const addTotalmarks = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_marks_total',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_marks_total',
          data
        );
      }
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
    }
  };

  const addmarks1 = async data => {
    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_attendance',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_attendance',
          data
        );
      }
      dispatch({
        type: 'ADD_IMPORT',
        payload: {
          user1: response.data,
          updatesuccessfull1: true
        }
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: 'ADD_IMPORT_ERROR',
          payload: {
            err1: err.response.data,
            updatesuccessfull1: false
          }
        });
      }
      
    }
  };

  const addassignment = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_asmt_marks',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_asmt_marks',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  const addsee = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_see_marks',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_see_marks',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };
  const addLessonPlan = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_lesson_plan',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_lesson_plan',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  const addCES = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_course_exit_survey',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_course_exit_survey',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  const addattendance = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_stu_attendance',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_stu_attendance',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  const addlabIAmarks = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_lab_ia_marks',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_lab_ia_marks',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  const addactivity = async data => {
    

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_activity_marks',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_activity_marks',
          data
        );
      }
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      
    }
  };

  return (
    <FeeSetupContext.Provider
      value={{
        ...state,
        addprogram,
        addprogram1,
        addmarks,
        addmarks1,
        addassignment,
        addTotalmarks,
        addsee,
        addattendance,
        addlabIAmarks,
        addLessonPlan,
        addactivity,
        addCES
      }}
    >
      {children}
    </FeeSetupContext.Provider>
  );
};

export default FeeSetupContext;
