import React from 'react';
import {
  Box,
  LinearProgress,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    // zIndex: 2000
  }
}));

const SlashScreen = () => {
  const classes = useStyles();
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <div className={classes.root}>
      {mobileDevice?
      <Box width={200}>
        <LinearProgress />
      </Box>:<Box width={400}>
        <LinearProgress />
      </Box>}
    </div>
  );
}

export default SlashScreen;
