import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import RightNavBar from './RightNavBar';
import TopBar from './TopBar';
import {
  DashboardProvider,
  useDashboardContext
} from 'src/contexts/DashboardContext';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 64,

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
      transitionDuration: 500
      //paddingRight: 70
    }
  },
  wrapper1: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 64,
    transitionDuration: 500,
    //paddingLeft: 90,
    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 70,
      transitionDuration: 500
    }
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { open, setOpen } = useDashboardContext();
  console.log(open);

  const theme1 = useTheme();

  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  return (
    <div className={classes.root}>
      <TopBar
        open={open}
        setOpen={setOpen}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onMobileNavClose={() => setOpen(false)}
      />
    {mobileDevice && (
        <RightNavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      )}
      <div className={open ? classes.wrapper : classes.wrapper1}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
  
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
